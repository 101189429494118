import React from 'react'
import { t } from '../../resources/t';

const ServiceModal: React.FC = () => {
  return (
    <div className="modal">
      <div className="modal-title">{t("Сервисный центр")}</div>
      <img src={require("../../assets/images/map.png")} alt="" />
      <div className="modal-descr">
        {t("Адрес")}: {t("Алмазарский район,Беруний 12Е")}
      </div>
      <div className="modal-descr">
        {t("Телефон")}: <a href="tel:+998993371111">+998 99 337 11 11</a>
      </div>
    </div>
  )
}

export default ServiceModal;