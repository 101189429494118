import React, { useState } from "react";
import "./style.scss";
import { t } from "../../resources/t";
import { CategoryTypes } from "../../models";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import BrandModal from "../Modal/BrandModal";
import ServiceModal from "../Modal/ServiceModal";

interface Props {
  categories: CategoryTypes[];
}

const CategoriesDrawer: React.FC<Props> = ({ categories }) => {
  const navigate = useNavigate();
  const [brandModal, setBrandModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);

  const openBrandModal = () => setBrandModal(true);

  const closeBrandModal = () => setBrandModal(false);

  const openServiceModal = () => setServiceModal(true);

  const closeServiceModal = () => setServiceModal(false);
  return (
    <div className="drawer">
      <div className="drawer-title">{t("Все категории")}</div>
      {categories.map((item: any) => (
        <span key={item.id} onClick={() => navigate(`/categories/${item.id}`, { state: { category_id: item.id } })}>
          {item[`name_${localStorage.cur_lang}`]}
        </span>
      ))}
      <span onClick={openBrandModal}>{t("О бренде")}</span>
      <span onClick={openServiceModal}>{t("Сервисный центр")}</span>
      <span><a href="tel:+998993371111">{t("Контакты")}</a></span>
      <Modal
        isOpen={brandModal}
        onRequestClose={closeBrandModal}
      >
        <BrandModal />
      </Modal>
      <Modal
        isOpen={serviceModal}
        onRequestClose={closeServiceModal}
      >
        <ServiceModal />
      </Modal>
    </div>
  );
};

export default CategoriesDrawer;
