import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { t } from "../../resources/t";
import Logo from "../../assets/icons/logo";
import FlagRu from "../../assets/icons/flagRu";
import Search from "../../assets/icons/search";
import FlagUz from "../../assets/icons/flagUz";
import FlagEn from "../../assets/icons/flagEn";
import Burger from "../../assets/icons/burger";
import { useNavigate } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import CategoriesDrawer from "../CategoriesDrawer";
import axios from "../../axios";
import { CategoryTypes } from "../../models";
import Modal from 'react-modal';
import Loading from "../Loading";
import SearchModal from "../Modal";
import types_arr from "./types_arr.json";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [lngMenu, setLngMenu] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [modal, setModal] = useState(false);
  const [typeId, setTypeId] = useState<number | null>(null);
  const [categories, setCategories] = useState<CategoryTypes[]>([]);
  const toggleDrawer = () => {
    setDrawer((prevState) => !prevState);
  };

  const openLngMenu = (lng: string) => {
    const clickHandler = (e: any) => {
      const elem = document.getElementById(`clickbox-${lng}`);
      if (elem) {
        const isClickInside = elem.contains(e.target);
        if (isClickInside) {
          setLngMenu((prevState) => !prevState);
        } else {
          setLngMenu(false);
          window.removeEventListener("click", clickHandler);
        }
      } else {
        window.removeEventListener("click", clickHandler);
      }
    };
    window.addEventListener("click", clickHandler);
  };

  const switchLng = (lng: string) => {
    localStorage.setItem("cur_lang", lng);
    setLngMenu(false);
    window.location.reload();
  };

  const openSubCatMenu = (typeId: number) => {
    const clickHandler = (e: any) => {
      const elem = document.getElementById(`clickbox-${typeId}`);
      if (elem) {
        const isClickInside = elem.contains(e.target);
        if (isClickInside) {
          setTypeId(typeId);
        } else {
          setTypeId(null);
          window.removeEventListener("click", clickHandler);
        }
      } else {
        window.removeEventListener("click", clickHandler);
      }
    };
    window.addEventListener("click", clickHandler);
  };

  const categoriesByTypes = useMemo(() => {
    if (!typeId) return [];
    if (typeId === 1) return categories.filter((item) => [22].includes(item.id));
    if (typeId === 2) return categories.filter((item) => [16, 18, 19].includes(item.id));
    if (typeId === 3) return categories.filter((item) => [14].includes(item.id));
    if (typeId === 4) return categories.filter((item) => [17, 15, 3, 21, 20, 1, 23, 26, 27, 28].includes(item.id));
    if (typeId === 5) return categories.filter((item) => [25].includes(item.id));
  }, [categories, typeId]);

  useEffect(() => {
    if (!localStorage.cur_lang) {
      localStorage.setItem("cur_lang", "ru");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/client/category_list/")
      .then((response) => {
        setCategories(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="header">
      {loading && <Loading />}
      <div className="header-block">
        <div className="header-block-row container">
          <div className="header-block-row-left">
            <div className="header-block-row-left-item">
              <a href="https://maps.app.goo.gl/yU1rbFfeCtUzexzT9" target="_blank">
                <span>{t("Где купить?")}</span>
              </a>
            </div>
            {localStorage.cur_lang !== "uz" && <div className="header-block-row-left-empty" />}
            {/* <div className="header-block-row-left-item">
              <span>{t("Сервисный центр")}</span>
            </div>
            <div className="header-block-row-left-item">
              <span>{t("О компании")}</span>
            </div> */}
          </div>
          <div
            className="header-block-row-center"
            onClick={() => navigate("/")}
          >
            <Logo />
          </div>
          <div className="header-block-row-right">
            <div className="header-block-row-right-number">
              +998 99 337 11 11
            </div>
            {localStorage.cur_lang === "ru" && (
              <div
                id="clickbox-ru"
                className="header-block-row-right-lang"
                onClick={() => openLngMenu("ru")}
              >
                <FlagRu />
                <span>РУС</span>
                {lngMenu && (
                  <div className="header-block-row-right-lang-menu">
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("ru")}
                    >
                      <FlagRu />
                      <span>РУС</span>
                    </div>
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("uz")}
                    >
                      <FlagUz />
                      <span>UZB</span>
                    </div>
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("en")}
                    >
                      <FlagEn />
                      <span>ENG</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {localStorage.cur_lang === "uz" && (
              <div
                id="clickbox-uz"
                className="header-block-row-right-lang"
                onClick={() => openLngMenu("uz")}
              >
                <FlagUz />
                <span>UZB</span>
                {lngMenu && (
                  <div className="header-block-row-right-lang-menu">
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("ru")}
                    >
                      <FlagRu />
                      <span>РУС</span>
                    </div>
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("uz")}
                    >
                      <FlagUz />
                      <span>UZB</span>
                    </div>
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("en")}
                    >
                      <FlagEn />
                      <span>ENG</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            {localStorage.cur_lang === "en" && (
              <div
                id="clickbox-en"
                className="header-block-row-right-lang"
                onClick={() => openLngMenu("en")}
              >
                <FlagEn />
                <span>ENG</span>
                {lngMenu && (
                  <div className="header-block-row-right-lang-menu">
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("ru")}
                    >
                      <FlagRu />
                      <span>РУС</span>
                    </div>
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("uz")}
                    >
                      <FlagUz />
                      <span>UZB</span>
                    </div>
                    <div
                      className="header-block-row-right-lang-menu-item"
                      onClick={() => switchLng("en")}
                    >
                      <FlagEn />
                      <span>ENG</span>
                    </div>
                  </div>
                )}
              </div>
            )}
            <button className="header-block-row-right-search" onClick={() => setModal(true)}>
              <Search />
            </button>
          </div>
        </div>
        <div className="header-block-row container line">
          <div className="header-block-row-categories">
            <div
              className="header-block-row-categories-item"
              onClick={toggleDrawer}
            >
              <span className="header-block-row-categories-item-all">{t("Все категории")}</span>
              <Burger />
            </div>
            {types_arr.map((item: any) => (
              <div className="header-block-row-categories-item" id={`clickbox-${item.id}`} key={item.id} onClick={() => openSubCatMenu(item.id)}>
                <span>{item[`name_${localStorage.cur_lang}`]}</span>
                {typeId === item.id && categoriesByTypes?.length && (
                  <div className="header-block-row-categories-item-subcat">
                    {categoriesByTypes?.map((el: any) => (
                      <div
                        className="header-block-row-categories-item-subcat-item"
                        onClick={() =>
                          navigate(`/categories/${el.id}`, {
                            state: { category_id: el.id },
                          })
                        }
                      >
                        <div className="header-block-row-categories-item-subcat-item-name">{el[`name_${localStorage.cur_lang}`]}</div>
                      </div>
                    )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Drawer open={drawer} onClose={toggleDrawer} direction="left">
        <CategoriesDrawer categories={categories} />
      </Drawer>
      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
      >
        <SearchModal />
      </Modal>
    </div>
  );
};

export default Header;
