import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.scss";
import { Pagination, Navigation, EffectCoverflow } from "swiper/modules";
import Product from "../Product";
import { useNavigate } from "react-router-dom";
import { ProductsTypes } from "../../models";

interface Types {
  products: ProductsTypes[];
}

const Slider: React.FC<Types> = ({ products }) => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <Swiper
        loop={true}
        pagination={{ clickable: true, }}
        navigation={true}
        modules={[Pagination, Navigation]}
        watchSlidesProgress={true}
        style={{ height: "auto", marginTop: 48, width: "100%" }}
        breakpoints={{
          320: { slidesPerView: 1.5, spaceBetween: 15, },
          576: { slidesPerView: 2.15, spaceBetween: 20, },
          768: { slidesPerView: 3, spaceBetween: 30, },
          1024: { slidesPerView: 4, spaceBetween: 30, },
        }}
      >
        {products.map((item: any) => (
          <SwiperSlide key={item.id}>
            <Product
              name={item[`name_${localStorage.cur_lang}`]}
              image={`${process.env.REACT_APP_API_BASE_URL}${item.image}`}
              onClick={() =>
                navigate(`/product/${item.id}`, {
                  state: { product_id: item.id },
                })
              }
              info={
                item.promotion
                  ? `${item?.promotion?.amount} ${item?.promotion?.monetary_unit}`
                  : ""
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;

interface ImageTypes {
  images: {
    id: number;
    image: string;
    product: number;
  }[];
}

export const ProductSlider: React.FC<ImageTypes> = ({ images }) => {
  return (
    <div className="container">
      {/* <Swiper
        // slidesPerView={images?.length > 1 ? 1.05 : images?.length}
        slidesPerView={1}
        spaceBetween={32}
        loop={true}
        navigation={false}
        modules={[Pagination, Navigation]}
        style={{ width: "100%" }}
      > */}
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={images?.length > 1 ? 1.2 : 'auto'}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={false}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        {images?.map((item) => (
          <SwiperSlide key={item.id}>
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}${item.image}`}
              alt=""
              width={"100%"}
              height={738}
              style={{ objectFit: "contain" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
