import React from 'react'

const Logo: React.FC = () => {
    return (
        <svg width="158" height="38" viewBox="0 0 158 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2026_1592)">
                <path d="M157.929 19.8089C157.471 19.701 156.977 19.5932 156.518 19.5213C151.686 19.018 149.322 21.6064 149.04 26.3519V37.1372H138.351L138.281 9.491H148.087V15.0274C148.229 15.0274 148.193 15.0274 148.229 14.9555C148.511 14.4163 148.687 13.6972 149.04 13.0861C150.874 9.74265 154.296 8.23272 158.035 8.84388" fill="white" />
                <path d="M41.3431 0C43.8477 0.251656 46.0348 2.55251 46.2464 5.10501C46.6344 9.95837 41.2373 13.0501 37.3922 10.1022C33.406 7.08231 35.2404 0.503311 40.1084 0C40.4259 0 41.0256 0 41.3431 0Z" fill="#6E00FF" />
                <path d="M33.0534 1.36682V8.70079L14.8158 29.768L33.0534 29.9118V37.1379H0L0.105827 29.6242L17.9201 8.80864L0.141103 8.73674L0.0705515 1.29492L33.0534 1.36682Z" fill="white" />
                <path d="M135.494 25.7776H114.787V26.8562C114.787 27.0359 115.034 27.7909 115.105 28.0066C116.587 31.7814 122.266 32.7161 124.277 28.9413H134.507C133.342 33.3273 129.674 36.2034 125.441 37.246C114.47 39.9423 103.393 35.3765 104.064 22.4342C104.416 15.4957 108.967 10.4986 115.564 9.16837C122.548 7.76629 130.415 9.99524 133.589 16.8978C134.859 19.6301 135.353 22.7578 135.459 25.7776H135.494ZM124.665 20.3131C124.665 16.5023 121.102 14.525 117.715 15.6755C116.304 16.1428 114.823 17.4011 114.787 19.3065V20.3131H124.665Z" fill="white" />
                <path d="M52.4903 12.9413C52.455 11.1078 52.5608 9.23839 53.0547 7.44085C54.9243 0.682103 60.8153 1.72468 66.3889 1.54492C66.8475 1.54492 67.3413 1.54492 67.7999 1.54492L67.7294 8.41152H66.3889C66.0714 8.41152 65.1542 8.66318 64.8368 8.80698C63.2494 9.49005 63.3199 11.4314 63.2494 12.9054H67.7999V19.1608L63.3199 19.2327V37.1362H52.4197L52.4903 19.2327H49.4565V12.9054H52.4903V12.9413Z" fill="white" />
                <path d="M69.4229 12.9415L72.5977 12.8696C72.5977 9.3464 72.8093 5.14016 76.0547 3.09096C79.5117 0.897964 83.9212 1.76078 87.7662 1.58103L87.8368 8.37573C86.6727 8.37573 84.9794 8.51954 84.0623 9.3464C83.2862 10.0654 83.2862 11.827 83.2862 12.8696H87.7662V19.2688H83.2862V37.1723H72.5271L72.5977 19.3407L69.4229 19.2688V12.9415Z" fill="white" />
                <path d="M100.994 1.43829L100.924 37.1374C97.3255 37.1734 93.7627 37.0296 90.1646 37.0655L90.2351 1.40234H100.994V1.43829Z" fill="white" />
                <path d="M46.0699 12.8711V37.2098H35.3813V12.943L46.0699 12.8711Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2026_1592">
                    <rect width="158" height="38" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Logo