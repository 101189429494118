import React from 'react';
import "./style.scss";
import { t } from '../../resources/t';

const BrandModal: React.FC = () => {
    return (
        <div className='modal'>
            <div className="modal-title">{t("О бренде")}</div>
            <div className='modal-descr'>
                Бренд ZIFFLER начал свою деятельность в рынке Узбекистана с 2015 года.
                Наш бренд является одним из лидирующих торговых марок в Узбекистане в области бытовой техники.
                Приоритет нашего бренда совокупляет в себя качество, удобство, индивидуальный   дизайн и приемлемая цена,
                так как мы ценим надежность и качество, а потому наша техника сочетает в себе передовые технологии и максимальную практичность.
                Мы являемся выбором многих довольных клиентов, так как мы наш продукт отвечает соответствует высоким требованием.
            </div>
            <div className='modal-descr'>
                На данный момент компания реализует более 20 видов крупной и мелкой бытовой техники так как:
            </div>
            <span>-Телевизоры</span>
            <span>-Стиральные машины</span>
            <span>-Холодильники</span>
            <span>-Кондиционеры</span>
            <span>-Микроволновые печи </span>
            <span>-Морозильные шкафы (лари и стоячие)</span>
            <span>-Вытяжки</span>
            <span>-Варочные панели</span>
            <span>-Встраиваемые духовые шкафы</span>
            <span>-Встраиваемые микроволновые  печи</span>
            <span>-Газовые плиты</span>
            <span>-Водяные диспенсеры</span>
            <span>-Пылесосы</span>
            <span>-Мясорубки</span>
            <span>-Утюги</span>
            <span>-Блендеры</span>
            <span>-Чопперы</span>
            <span>-Миксеры </span>
            <span>-Чайники</span>
        </div>
    )
}

export default BrandModal;