// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
  width: 350px;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #2b2a29;
  display: flex;
  flex-direction: column;
}
.drawer-title {
  font-size: 24px;
  font-weight: 700;
  color: #fefefe;
  margin-bottom: 16px;
}
.drawer span {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #898989;
  cursor: pointer;
}
.drawer span a {
  font-size: 16px;
  font-weight: 500;
  color: #898989;
  text-decoration: none;
}
.drawer span a:hover {
  color: #6E00FF;
}
.drawer span:hover {
  color: #6E00FF;
}

.EZDrawer nav {
  width: 350px !important;
  background-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/components/CategoriesDrawer/style.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;AAEJ;AAAE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,qBAAA;AAGN;AAFM;EACE,cAAA;AAIR;AADI;EACE,cAAA;AAGN;;AAEA;EACE,uBAAA;EACA,wCAAA;AACF","sourcesContent":[".drawer {\n  width: 350px;\n  height: 100vh;\n  padding: 20px;\n  box-sizing: border-box;\n  background-color: #2b2a29;\n  display: flex;\n  flex-direction: column;\n  &-title {\n    font-size: 24px;\n    font-weight: 700;\n    color: #fefefe;\n    margin-bottom: 16px;\n  }\n  & span {\n    margin-top: 16px;\n    font-size: 16px;\n    font-weight: 500;\n    color: #898989;\n    cursor: pointer;\n    & a {\n      font-size: 16px;\n      font-weight: 500;\n      color: #898989;\n      text-decoration: none;\n      &:hover {\n        color: #6E00FF;\n      }\n    }\n    &:hover {\n      color: #6E00FF;\n    }\n  }\n}\n\n.EZDrawer nav {\n  width: 350px !important;\n  background-color: transparent !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
