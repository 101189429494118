import React, { useState } from 'react';
import "./style.scss";
import Logo from '../../assets/icons/logo';
import { t } from '../../resources/t';
import { SocialLinks } from '../../assets/icons/socialLinks';
import Modal from 'react-modal';
import BrandModal from '../Modal/BrandModal';
import ServiceModal from '../Modal/ServiceModal';

const Footer: React.FC = () => {
    const [brandModal, setBrandModal] = useState(false);
    const [serviceModal, setServiceModal] = useState(false);

    const openBrandModal = () => setBrandModal(true);

    const closeBrandModal = () => setBrandModal(false);

    const openServiceModal = () => setServiceModal(true);

    const closeServiceModal = () => setServiceModal(false);
    return (
        <div className='footer'>
            <div className="footer-content container">
                <div className="footer-content-left">
                    <Logo />
                    <div className="footer-content-left-info">
                        <span>{t("Адрес")}:</span>
                        <span>{t("Ташкент, ул. Ботира Закирова, 7, Ташкент")}</span>
                    </div>
                    <div className="footer-content-left-info">
                        <span>{t("Контакты")}:</span>
                        <span>+998 99 337 11 11</span>
                        <span>info@ziffler.uz</span>
                    </div>
                    <div className="footer-content-left-links">
                        <button>
                            <a href="https://www.facebook.com/ziffleruzbekistan?mibextid=ZbWKwL" target="_blank">
                                <SocialLinks.Facebook />
                            </a>
                        </button>
                        <button>
                            <a href="https://www.instagram.com/ziffleruz?igsh=MTdsenRvcWxjN2Jv" target="_blank">
                                <SocialLinks.Instagram />
                            </a>
                        </button>
                        <button>
                            <a href="https://t.me/ziffleruzb" target="_blank">
                                <SocialLinks.Telegram />
                            </a>
                        </button>
                        <button>
                            <a href="https://www.youtube.com/@ziffleruz3943" target="_blank">
                                <SocialLinks.YouTube />
                            </a>
                        </button>
                    </div>
                </div>
                <div className="footer-content-right">
                    <div className="footer-content-right-block">
                        <span onClick={openBrandModal}>{t("О бренде")}</span>
                        <span onClick={openServiceModal}>{t("Сервисный центр")}</span>
                        {/* <span>{t("Официальные дилеры")}</span> */}
                        <span><a href="tel:+998993371111">{t("Контакты")}</a></span>
                        {/* <span>{t("Карьера")}</span> */}
                    </div>
                    {/* <div className="footer-content-right-block">
                        <span>{t("Стиральные машины")}</span>
                        <span>{t("Кондиционеры")}</span>
                        <span>{t("Телевизоры")}</span>
                        <span>{t("Мониторы")}</span>
                        <span>{t("Холодильники")}</span>
                        <span>{t("Морозильники")}</span>
                        <span>{t("Газовые плиты")}</span>
                        <span>{t("Духовые Печи")}</span>
                        <span>{t("Микроволновые Печи")}</span>
                        <span>{t("Варочные Панели")}</span>
                    </div>
                    <div className="footer-content-right-block">
                        <span>{t("Кухонные смесители")}</span>
                        <span>{t("Кухонные мойки")}</span>
                        <span>{t("Мелкая Бытовая Техника")}</span>
                        <span>{t("Посудомоечные Машины")}</span>
                        <span>{t("Вытяжки")}</span>
                        <span>{t("Масляные Обогреватели")}</span>
                        <span>{t("Вода диспенсеры")}</span>
                        <span>{t("Кресло")}</span>
                        <span>{t("Стайлер")}</span>
                        <span>{t("Акция")}</span>
                    </div> */}
                </div>
            </div>
            <div className="footer-bottom container">
                <span>© 2024 Ziffler. {t("Все права защищены.")}</span>
                <div className="footer-bottom-political">
                    <span>{t("Политика конфиденциальности")}</span>
                    <span>{t("Политика использования файлов cookie")}</span>
                </div>
            </div>
            <Modal
              isOpen={brandModal}
              onRequestClose={closeBrandModal}
            >
                <BrandModal />
            </Modal>
            <Modal
              isOpen={serviceModal}
              onRequestClose={closeServiceModal}
            >
                <ServiceModal />
            </Modal>
        </div>
    )
}

export default Footer