import React, { useEffect, useState } from 'react';
import "./style.scss";
import { t } from '../../resources/t';
import axios from '../../axios';
import { useNavigate } from 'react-router-dom';

const SearchModal: React.FC = () => {
    const navigate = useNavigate();

    const [search, setSearch] = useState('');
    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios.get(`/client/product_list/?search=${search}`)
            .then(response => {
                setProducts(response.data)
            })
    }, [search]);
    return (
        <div className='modal'>
            <input
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={t("Введите название продукта")}
            />
            <div className="modal-products">
                {products.map((item: any) => (
                    <div
                        key={item.id}
                        className="modal-products-item"
                        onClick={() =>
                            navigate(`/product/${item.id}`, {
                                state: { product_id: item.id },
                            })
                        }
                    >
                        <img
                            src={item?.image
                                ? `${process.env.REACT_APP_API_BASE_URL}${item.image}`
                                : require("../../assets/images/product-img.png")
                            }
                        />
                        <span>{item[`name_${localStorage.cur_lang}`]}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SearchModal;