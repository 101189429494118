// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/prev.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/icons/next.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-slide {
  height: auto !important;
}
.swiper-pagination {
  text-align: left;
}
.swiper-pagination-bullet {
  background: #434242;
}
.swiper-pagination-bullet-active {
  background: #898989;
}
.swiper-button-prev {
  top: auto;
  left: auto;
  right: 65px;
  bottom: 0;
  width: auto;
  height: auto;
  margin-top: 0;
  align-items: flex-end;
  justify-content: flex-end;
}
.swiper-button-prev::after {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.swiper-button-next {
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin-top: 0;
  align-items: flex-end;
  justify-content: flex-end;
}
.swiper-button-next::after {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/components/Slider/style.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AACI;EACE,mBAAA;AACN;AAAM;EACE,mBAAA;AAER;AAEE;EACE,SAAA;EACA,UAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;EACA,yBAAA;AAAJ;AACI;EACE,gDAAA;AACN;AAEE;EACE,SAAA;EACA,UAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;EACA,yBAAA;AAAJ;AACI;EACE,gDAAA;AACN","sourcesContent":[".swiper {\n  &-slide {\n    height: auto !important;\n  }\n  &-pagination {\n    text-align: left;\n    &-bullet {\n      background: #434242;\n      &-active {\n        background: #898989;\n      }\n    }\n  }\n  &-button-prev {\n    top: auto;\n    left: auto;\n    right: 65px;\n    bottom: 0;\n    width: auto;\n    height: auto;\n    margin-top: 0;\n    align-items: flex-end;\n    justify-content: flex-end;\n    &::after {\n      content: url(\"../../assets/icons/prev.svg\");\n    }\n  }\n  &-button-next {\n    top: auto;\n    left: auto;\n    right: 0;\n    bottom: 0;\n    width: auto;\n    height: auto;\n    margin-top: 0;\n    align-items: flex-end;\n    justify-content: flex-end;\n    &::after {\n      content: url(\"../../assets/icons/next.svg\");\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
